import mapboxgl from 'mapbox-gl';

import { property_api_run_ai_comps_url, property_api_run_simple_ai_comps_url, property_api_run_ai_city_comps_url, property_api_run_combined_comps_url, property_api_run_historical_comp_price_url, property_api_run_historical_url, property_api_run_city_comps_url, property_api_run_comps_url, property_api_run_individual_comps_url } from "../utility/Config"
import { Settings, CompBucketOption, today, DataSetType, CompTypeOption } from '../utility/Global';
import { makePostRequest } from '../utility/Services';

import { formatNumber, formatDate, formatShortNumber, roundToNearest, isEmpty } from '../utility/Helper.js'
import Lot from '../Tax-Lot';

export var compLotData = []

export var compsREValues = {}
export var compMileRanges = {}

var compsRunning = false

var mapMarkers = {};

export const LTDS_AVG_PRICE_LABEL = "LTDS AVG Price";
export const AVG_PRICE_LABEL = "AVG Price";
export const PSF_PRICE_LABEL = "PSF Adjusted";
export const URBYN_PRICE_LABEL = "Urbyn Adjusted";
export const FINAL_PRICE_LABEL = "Final Adjusted";
//export const PSF_LABEL = "PSF";
export const LAND_LABEL = "Land";

// Standard Deviation
export const MinPricePerSqftElim = -3
export const MaxPricePerSqftElimLow = -0.50
export const MinPricePerSqftElimHigh = 1.00
export const MaxPricePerSqftElim = 3

export const CAP_MIN_Z = -2;
export const CAP_MAX_Z = 2;
export const getMaxZCap = (avg) => {
  
  if (avg < 60) {
    return 3
  } else
   if (avg < 70) {
    return 2.75
  } else if (avg < 80) {
    return 2.5
  } else if (avg < 90) {
    return 2.25
  }
  return CAP_MAX_Z
}

export const MIN_Z = -1;
export const MAX_Z = 1;

export const QUARTER_MIN_Z = -0.5;
export const QUARTER_MAX_Z = 0.5;

export const MIN_COMPOSITE_BUCKET_Z = -1.25;
export const MAX_COMPOSITE_BUCKET_Z = 2.25;


export const BucketType = {
  Regular: 1,
  Composyte: 2,
  Extended: 3 // Lowest / Highest
}

export const csvColumns = {
  //bucket: 'Bucket',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  ltds: 'LTDS',
  composyteLikenessScore: 'L',
  composyteTimeDistanceScore: 'TD',
  composyteSizeScore: 'S',
  distance: 'Distance',
  documentAmount: 'Amount',
  documentDate: 'Closing Date',
  daysFromDocument: 'Days From',
  finalArea: 'Final Sqft',
  totalArea: 'Total Sqft',
  urbynArea: 'Urbyn Choice',
  faArea: 'FA Choice',
  livingArea: 'Living Sqft',
  listingArea: 'Listing Sqft',
  basementArea: 'Basement',
  garageArea: 'Garage',
  atticArea: 'Attic',
  lotArea: 'Lot Sqft',
  pricePerSqft: 'PSF',
  sellerNames: 'Sellers',
  buyerNames: 'Buyers',
  heldString: 'Time Held',
  stories: 'Stories',
  totalUnits: 'Total Units',
  bedrooms: 'Bedrooms',
  bathrooms: 'Bathrooms',
  yearBuilt: 'Year Built',
  neighborhood: 'Neighborhood/Town',
  style: 'Style',
  exterior: 'Exterior'
}

export function changeMarkers(propertyId, cName) {
  //markers[0].querySelector('svg g[fill="#d5fdff"]').attr('fill', 'orange');
  const m = document.getElementById(`marker-${propertyId}`)
  if (m) {
    if (m.classList.contains(cName)) {
      m.classList.remove(cName)
    } else {
      m.classList.add(cName)
    }
  }
}

export const reorderData = (properties, selectedLot) => {
  if (!selectedLot || isEmpty(selectedLot)) return properties
  const top = properties?.find(_ => _.propertyId?.toString() === selectedLot?.propertyId?.toString())
  if (top !== undefined) {
    properties.splice(properties.indexOf(top), 1)
    properties.unshift(top)
  }
  return properties
}

const lotColor = (item) => {
  if (item.isEntity) {
    return "#ff2b00"
  } else {
    return "#d5fdff"
  }
}

export const composyteBucketColor = (color, composyteCount) => {
  if (composyteCount === 2) {
    return "#cc00ff"
  } else if (composyteCount === 3) {
    return "#083ac9"
  } else if (composyteCount === 4 || composyteCount === 5) {
    return "#000000"
  }
  return color
}

export const bucketColor = (bucket, opacity = true) => {
  let color = ''
  if (bucket === CompBucketOption.HIGH) {
    color = "#ff0000"
  } else if (bucket === CompBucketOption.LOW) {
    color = "#21ba45"
  } else if (bucket === CompBucketOption.MIDDLETOP) {
    color = "#ffc100"
  } else if (bucket === CompBucketOption.MIDDLE) {
    color = "#ffc100"
  } else if (bucket === CompBucketOption.MIDDLEBOTTOM) {
    color = "#ffc100"
  } else if (bucket === CompBucketOption.MIDDLELOW) {
    color = "#20b2aa"
  } else if (bucket === CompBucketOption.MIDDLEHIGH) {
    color = "#ff7400"
  } else if (bucket === CompBucketOption.DISTRESS) {
    color = "#cc00ff"
  } else if (bucket === CompBucketOption.EXTREME) {
    color = "#cc00ff"
  } else if (bucket === CompBucketOption.REMOVED) {
    color = "#d3d3d3"
  } else if (bucket === CompBucketOption.SUBJECT) {
    color = "#0000ff"
  } else if (bucket === CompBucketOption.APPRAISER) {
    color = "#083ac980"
  }

  if (!opacity) {
    color += "44"
  }
  return color
}

export const opaqueColor = (color, opacity = true) => {
  if (!opacity) {
    color += "44"
  }
  return color
}

const averageGeolocation = (coords) => {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = coord.latitude * Math.PI / 180;
    let longitude = coord.longitude * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  let total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: centralLatitude * 180 / Math.PI,
    longitude: centralLongitude * 180 / Math.PI
  };
}


export function removeCompMarkers(map) {
  if (map) {
    const { _mapId } = map

    let markers = mapMarkers[_mapId]

    if (markers) {
      markers.forEach(item => {
        item.remove()
      })

      markers.splice(0, markers.length)
    }
  }
}

export function removeComps(map) {
  removeCompMarkers(map)

  if (map.getLayer('comps-labels')) {
    map.removeLayer('comps-labels');
  }

  if (map.getSource('comps')) {
    map.removeSource('comps');
  }

  compsRunning = false

  compsREValues = {}
}

export function getBucketFromScore(score, avgLtds = null) {
  const capMaxZ = avgLtds ? getMaxZCap(avgLtds) : 3
  if (score > capMaxZ) return CompBucketOption.EXTREME;
  if (score > MAX_Z && score <= capMaxZ)
    return CompBucketOption.HIGH;
  if (score > QUARTER_MAX_Z && score <= MAX_Z)
    return CompBucketOption.MIDDLEHIGH;
  if (score <= QUARTER_MAX_Z && score >= QUARTER_MIN_Z)
    return CompBucketOption.MIDDLE;
  if (score < QUARTER_MIN_Z && score >= MIN_Z)
    return CompBucketOption.MIDDLELOW;
  if (score < MIN_Z && score >= CAP_MIN_Z)
    return CompBucketOption.LOW;
  if (score < CAP_MIN_Z) return CompBucketOption.DISTRESS;
}

function getPropertyFeatures(lots) {
  const propertyFeatures = lots.map(function (item, index) {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [item.longitude, item.latitude]
      },
      properties: {
        compType: item.compType,
        type: item.type,
        address: item.address,
        city: item.city,
        state: item.state,
        ownername: item.ownerName,
        sellers: item.sellerNames,
        buyers: item.buyerNames,
        isEntity: item.isEntity,
        id: index,
        borough: item.boroughname,
        zip: item.zip,
        // lotdimensions: (item.lotFront ?? 'NA') + " ft x " + (item.lotDepth ?? 'NA') + " ft",
        // buildingdimensions: (item.bldgFront ?? 'NA') + " ft x " + (item.bldgFepth ?? 'NA') + " ft",
        stories: item.stories,
        saleprice: `$${formatNumber(item.documentAmount)}`,
        shortSalePrice: formatShortNumber(item.documentAmount),
        ltds: item.ltds,
        zoning: item.zoning,
        yearBuilt: item.yearBuilt,
        buildingsqft: formatNumber(item.bldgArea),
        lotsqft: formatNumber(item.lotArea),
        lastsaledate: formatDate(item.documentDate),
        totalunits: item.totalUnits,
        neighborhood: item.neighborhood,
        units: item.unitstotal,
        propertyId: item.propertyId,
        longitude: item.longitude,
        latitude: item.latitude,
        isSelected: item.isSelected,
        color: item.bucket === CompBucketOption.REMOVED ? bucketColor(getBucketFromScore(item.bucketZScore), false) : item.altColors["finalColor"],
      }
    }
  });

  return propertyFeatures
}

export function runCompMarkers(properties, map, options, handleMarkerClick = null, handleHoverMarker = null, handleSelectedComp = null, handleDeselectedComp = null) {
  const { resetView = true, overrideMarkerClass = true, isMobile = false } = options || {}
  if (properties.length === 0) return
  const propertyFeatures = getPropertyFeatures(properties)

  setMarkers(propertyFeatures, handleMarkerClick, handleHoverMarker, handleSelectedComp, handleDeselectedComp, map, overrideMarkerClass)

  if (resetView) {
    const allCoordinates = properties.map(function (item) {
      return {
        latitude: item.latitude,
        longitude: item.longitude
      }
    })

    const centerLocation = averageGeolocation(allCoordinates);

    let centerCoords = [centerLocation.longitude + .035, centerLocation.latitude - .008]
    let zoom = 14
    if (isMobile) {
      centerCoords = [centerLocation.longitude, centerLocation.latitude + .002]
      zoom = 13
    }

    map.flyTo({
      center: centerCoords, // R/L, U/D
      zoom: zoom,
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    });

  }
}

function setMarkers(propertyFeatures, handleMarkerClick, handleHoverMarker, handleCompPopup, handleDeselectedComp, map, overrideMarkerClass) {

  let markers = mapMarkers[map._mapId]
  if (!markers) markers = []
  mapMarkers[map._mapId] = markers

  propertyFeatures.forEach(function (marker) {
    // create a HTML element for each feature
    const m = new mapboxgl.Marker({ color: marker.properties.color, scale: 0.1 })
      .setLngLat(marker.geometry.coordinates)     
      .addTo(map)

    const markerDiv = m.getElement();
    if (overrideMarkerClass) {
      markerDiv.className = `${markerDiv.className} comp-marker`
      markerDiv.style.backgroundColor = marker.properties.color
      markerDiv.textContent = `${marker.properties.shortSalePrice}\n${roundToNearest(marker.properties.ltds)}%`
    }
    //const cDiv = document.createElement("div")
    // let p = document.createElement("p")
    // p.textContent = "$12039123"
    // markerDiv.appendChild(p)
    //markerDiv.appendChild(cDiv)

    markerDiv.setAttribute('id', `marker-${marker.properties.propertyId}`)
    // if (!marker.properties.isSelected) {
    //   markerDiv.classList.add('deselected')
    // }
    markerDiv.addEventListener('mouseenter', () => {
      //console.log("mouse enter")
      handleCompPopup && handleCompPopup(marker.properties)
      //m.togglePopup()

    });
    markerDiv.addEventListener('mouseleave', () => {
      //.console.log("mouse leave")
      handleCompPopup && handleCompPopup(null)
      //m.togglePopup()

      //handleHoverMarker && handleHoverMarker(null)
    });
    markerDiv.addEventListener('click', () => {
      //m.togglePopup()
      handleCompPopup && handleCompPopup(marker.properties, true)
    });

    markerDiv.addEventListener('dblclick', () => {
      //m.togglePopup()
      handleMarkerClick && handleMarkerClick(marker.properties.propertyId)
    });

    markerDiv.addEventListener('contextmenu', function (ev) {
      console.log("Context Menu")
      ev.preventDefault();
      handleDeselectedComp && handleDeselectedComp(marker.properties.propertyId)
      return false;
    }, false);


    markers.push(markerDiv)
  });
}

export async function runIndividualComps(selectedLot, mbbls, options) {
  const { type, bedrooms = "", radius, ds = DataSetType.PLUTO, date = today(), version = 12 } = options

  var compOptions = {
    propertyId: selectedLot.propertyId,
    type,
    date,
    months: Settings.extendedCompsMonths,
    bedrooms,
    dataset: ds,
    radius,
    version
  }

  var individualCompOptions = {
    compOptions,
    propertyIds: mbbls
  }

  const result = await makePostRequest(`${property_api_run_individual_comps_url}`, individualCompOptions)

  console.log(result)
  return result
}

export async function runComps(selectedLot, options, callback) {
  let { type, bedrooms = '', date = today(), ds = DataSetType.PLUTO, forceCombined = false, version = 12 } = options
  if (Lot.isFA(selectedLot)) {
    Settings.extendedCompsRadius = 1
  }
  if (!bedrooms) bedrooms = ''
  if (!date) date = today() 
  compsRunning = true

  var months = Settings.extendedCompsMonths
  var radius = Settings.extendedCompsRadius
  /*if (version === 7) {
    radius = 0.5
    months = 6
  }*/

  var compOptions = {
    propertyId: selectedLot.propertyId,
    type,
    date,
    months,
    bedrooms,
    dataset: ds,
    radius,
    overrideCombined: forceCombined,
    version
  }
  const result = await makePostRequest(`${property_api_run_comps_url}`, compOptions)

  if (!result || !compsRunning) {
    return callback && callback({}, true)
  }

  compsRunning = false

  if (result) {
    if (result.isCombined) {
      callback(result, false)
    } else {
      callback && callback({compsData: result, compOptions}, false)
    }
  }
}

export async function runCombinedComps(selectedLot, options, callback) {
  let { type = CompTypeOption.DEED, bedrooms = '', date = today(), ds = DataSetType.FIRST_AMERICAN, version = 12 } = options
  if (Lot.isFA(selectedLot)) {
    Settings.extendedCompsRadius = 1
  }
  if (!bedrooms) bedrooms = ''
  if (!date) date = today() 

  var compOptions = {
    propertyId: selectedLot.propertyId,
    type,
    date,
    months: Settings.extendedCompsMonths,
    bedrooms,
    dataset: ds,
    radius: Settings.extendedCompsRadius,
    version
  }
  const result = await makePostRequest(`${property_api_run_combined_comps_url}`, compOptions)

  if (result) {
    callback && callback(result )
  }
}

export async function runHistoricalCompPrice(selectedLot, options, callback) {
  let { type, bedrooms = '', ds = DataSetType.PLUTO, version = 12 } = options

  if (!selectedLot.latestDeed || selectedLot.latestDeed?.amount === 0) return
  var compOptions = {
    propertyId: selectedLot.propertyId,
    type,
    date: selectedLot.latestDeed.date,
    months: 12,
    bedrooms,
    dataset: selectedLot.type,
    radius: 1,
    version
  }

  const result = await makePostRequest(`${property_api_run_historical_comp_price_url}`, compOptions)

  if (result) {
    callback && callback(result);
  }
}

export async function runAIComps(selectedLot, options, callback, isSimple = false) {
  options = {
    ...options,
    propertyId: selectedLot.propertyId
  }

  const aiCompsUrl = isSimple ? property_api_run_simple_ai_comps_url : property_api_run_ai_comps_url
  const result = await makePostRequest(`${aiCompsUrl}`, options)

  if (result) {
    callback && callback(result)
  } else {
    return callback && callback({})
  }
}

export async function runCityComps(selectedLot, options, callback) {  
  compsRunning = true

  options = {
    ...options,
    propertyId: selectedLot.propertyId
  }
  const result = await makePostRequest(`${property_api_run_city_comps_url}`, options)

  if (!result || !compsRunning) {
    return callback && callback({}, true)
  }

  compsRunning = false

  if (result) {
    callback && callback({compsData: result, compOptions: options}, false)
  }
}

export async function runAICityComps(selectedLot, options, callback) {
  options = {
    ...options,
    propertyId: selectedLot.propertyId
  }
  const result = await makePostRequest(`${property_api_run_ai_city_comps_url}`, options)

  if (result) {
    callback && callback(result)
  } else {
    return callback && callback({})
  }
}

export async function runHistoricalComps(selectedLot, callback) {
  compsRunning = true;

  var compOptions = {
    propertyId: selectedLot.propertyId,
    type: CompTypeOption.DEED,
    date: today(),
    months: 12,
    dataset: selectedLot.type,
    radius: 1  
  }

  const result = await makePostRequest(
    `${property_api_run_historical_url}`,
    compOptions
  );

  compsRunning = false;

  callback && callback(result);
}
